

























































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import {
  DecoContestModableEntry,
  DecoContestResult,
} from '@/game/infos/dataServerPackets';
import { Queuer } from '@/util/queuer';
import { generateLargeFishImage } from '../mainMenu/tools/largeFishImageGenerator';
import { decodeDecoSet } from '@/game/infos/decorativeInfos';
import fb from '@/store/sf-firestore';

@Component({
  components: {},
})
export default class DecoEntryForVote extends Vue {
  @Prop({ required: true }) public entry!: DecoContestResult;
  @Prop({ default: '1st' }) public ordinal!: string;
  @Prop({ default: '1' }) public place!: string;
  @Prop({ default: true }) public isAdminNom!: boolean;

  public imgSrc = '';
  public isLoading = true;

  @Watch('entry', { immediate: true })
  public async sumcheckChange(newVal: DecoContestModableEntry) {
    if (!newVal || !newVal.fishType) {
      this.imgSrc = '';
      return;
    }
    this.isLoading = true;
    const { fishType, decoSet } = newVal;
    const skinGroup = newVal.skin || 0;
    await Queuer.queue('fishImage', async () => {
      this.imgSrc = await generateLargeFishImage(
        fishType,
        skinGroup,
        decodeDecoSet(decoSet || ''),
      );
      this.isLoading = false;
    });
  }

  public showError(error: any) {
    this.$root.$emit('error', error);
  }
}
